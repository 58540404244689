<template>
  <v-card class="px-5 py-5">
    <PageHeader
      title="Crop Inventory"
      icon="mdi-flower-outline"
    />
    <v-form class="mb-5">
      <v-row v-for="delivery in customerDeliveries">
        <v-col cols="12" sm="6">
          <TextInput readonly :value="delivery.quantity" required :label="delivery.cropName" suffix="Quantity Kgs"/>
        </v-col>
        <v-col cols="12" sm="6">
          <CurrencyInput readonly :value="calculatedValue(delivery)" label="Value" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <CurrencyInput readonly :value="totalStockValue" label="Total stock value" required />
        </v-col>
        <v-col cols="12" sm="6">
          <CurrencyInput readonly :value="storageCosts" label="Storage costs" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols="12" sm="6">
          <CurrencyInput readonly :value="loanBalance" label="Loan balance" required />
        </v-col>
      </v-row>
    </v-form>
    <Heading :title="title" @title="$emit('title')" />
    <v-form v-model="valid" ref="cropInventoryForm">
      <v-row>
        <v-col cols="12" sm="6">
          <SelectInput
            :items="skus"
            class="text-left"
            item-text="text"
            item-value="value"
            required
            v-model="selectedCrop"/>
        </v-col>
        <v-col cols="12" sm="6">
          <TextInput v-model="inventory.quantity" required label="Quantity" type="number" suffix="Kgs/Bags"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <CurrencyInput readonly :value="cropValue" label="Value" required />
        </v-col>
        <v-col cols="12" sm="6">
          <TextInput readonly :value="customer.name" required label="Collected by" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <TextInput readonly :value="phoneNumber" required label="Mobile Number" type="number"/>
        </v-col>
      </v-row>
      <v-row class="mx-4 my-4">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="addNewCrop" >Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
  import { v4 as uuidv4 } from 'uuid';
  import TextInput from "@/components/TextInput";
  import PageHeader from "@/components/PageHeader";
  import CurrencyInput from "@/components/CurrencyInput";
  import SelectInput from "@/components/SelectInput";
  import Telephone from '@/components/Telephone';
  import Heading from '@/components/Heading';

  export default {
    components: { TextInput, PageHeader, Heading, CurrencyInput, SelectInput, Telephone },
    data: () => ({
      crop: {},
      inventory:{},
      selectedCrop: null,
      valid: false,
      title: "Crops collected",
      storageCosts: 0,
      loanBalance: 0
    }),

    computed: {
      cropValue(){
        if (this.selectedCrop && this.inventory.quantity) {
          return parseInt(this.selectedCrop.price_per_unit * this.inventory.quantity)
        }
      },
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      phoneNumber() { return this.customer.demographic.identity.number },
      skus() { return this.$store.getters.settings.skus.filter(e => e.category=="apex crop").map(item => { return {text: item.description, value: item}})},
      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentUuid() { return this.$store.getters.settings.fieldAgentUuid; },
      crops() { return this.$store.getters.settings.crops },
      customerDeliveries() {
        if (this.customer && this.customer.deliveries) {
          return this.$store.getters.deliveries.filter(e => e.customerUuid == this.fieldAgentUuid);
        }
        return [];
      },
      totalStockValue(){
        let totalSum = 0;
        for (let i = 0; i < this.customerDeliveries.length; i++) {
          const item = this.customerDeliveries[i];
          const quantity = item.quantity;
          const pricePerUnit = item.price_per_unit;
          totalSum += (quantity * pricePerUnit);
        }
        return totalSum
      }
    },

    methods: {
      calculatedValue(item) {
        return parseInt(item.price_per_unit * item.quantity)
      },
      addNewCrop() {
        const delivery = {
          uuid: uuidv4(),
          createdAt: new Date().toISOString(),
          cropName: this.selectedCrop.description,
          customerUuid: this.fieldAgentUuid,
          price_per_unit: this.selectedCrop.price_per_unit,
          deliveredToUuid: this.customer.uuid,
          cropId: parseInt(this.selectedCrop.code),
          quantity: parseInt(this.inventory.quantity),
          country:  'TZ',
          unit:     'kg',
          payload: { apex_tz: this.inventory },
          location: this.location,
          capturedById: this.fieldAgentId,
        }
        this.$store.dispatch('addDelivery', delivery);
      }
    }
  }
</script>
