<template>
  <div>
    <v-img :src="src"
          :max-width="this.imageHeight"
          v-bind:height="height"
          @load="$redrawVueMasonry()"></v-img>
  </div>
</template>

<script>
  import ImageDb from '@/store/ImageDb';

  export default {
    props: ['image', 'maxHeight', 'height'],

    data: () => ({
      src: undefined,
    }),

    computed: {
      imageHeight() {
        return (this.maxHeight || "400px")
      }
    },

    watch: {
      image: {
        immediate: true,
        handler(to) {
          if (typeof(to)==='string') {
            this.src = to;
          } else if (!!to && to.uuid) {
            ImageDb.url(to.uuid).then( e => this.src = e );
          }
        }
      },

    },
  }
</script>
